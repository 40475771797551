exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ecommerce-amazon-index-tsx": () => import("./../../../src/pages/ecommerce/amazon/index.tsx" /* webpackChunkName: "component---src-pages-ecommerce-amazon-index-tsx" */),
  "component---src-pages-ecommerce-amazon-precios-tsx": () => import("./../../../src/pages/ecommerce/amazon/precios.tsx" /* webpackChunkName: "component---src-pages-ecommerce-amazon-precios-tsx" */),
  "component---src-pages-ecommerce-claroshop-index-tsx": () => import("./../../../src/pages/ecommerce/claroshop/index.tsx" /* webpackChunkName: "component---src-pages-ecommerce-claroshop-index-tsx" */),
  "component---src-pages-ecommerce-compare-tsx": () => import("./../../../src/pages/ecommerce/compare.tsx" /* webpackChunkName: "component---src-pages-ecommerce-compare-tsx" */),
  "component---src-pages-ecommerce-index-tsx": () => import("./../../../src/pages/ecommerce/index.tsx" /* webpackChunkName: "component---src-pages-ecommerce-index-tsx" */),
  "component---src-pages-ecommerce-mercadolibre-index-tsx": () => import("./../../../src/pages/ecommerce/mercadolibre/index.tsx" /* webpackChunkName: "component---src-pages-ecommerce-mercadolibre-index-tsx" */),
  "component---src-pages-ecommerce-mercadolibre-precios-tsx": () => import("./../../../src/pages/ecommerce/mercadolibre/precios.tsx" /* webpackChunkName: "component---src-pages-ecommerce-mercadolibre-precios-tsx" */),
  "component---src-pages-ecommerce-shopify-index-tsx": () => import("./../../../src/pages/ecommerce/shopify/index.tsx" /* webpackChunkName: "component---src-pages-ecommerce-shopify-index-tsx" */),
  "component---src-pages-ecommerce-shopify-precios-tsx": () => import("./../../../src/pages/ecommerce/shopify/precios.tsx" /* webpackChunkName: "component---src-pages-ecommerce-shopify-precios-tsx" */),
  "component---src-pages-ecommerce-tiendanube-index-tsx": () => import("./../../../src/pages/ecommerce/tiendanube/index.tsx" /* webpackChunkName: "component---src-pages-ecommerce-tiendanube-index-tsx" */),
  "component---src-pages-ecommerce-zettle-index-tsx": () => import("./../../../src/pages/ecommerce/zettle/index.tsx" /* webpackChunkName: "component---src-pages-ecommerce-zettle-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-personal-compare-tsx": () => import("./../../../src/pages/personal/compare.tsx" /* webpackChunkName: "component---src-pages-personal-compare-tsx" */),
  "component---src-pages-personal-lite-tsx": () => import("./../../../src/pages/personal/lite.tsx" /* webpackChunkName: "component---src-pages-personal-lite-tsx" */),
  "component---src-pages-personal-profesional-tsx": () => import("./../../../src/pages/personal/profesional.tsx" /* webpackChunkName: "component---src-pages-personal-profesional-tsx" */),
  "component---src-pages-policies-tsx": () => import("./../../../src/pages/policies.tsx" /* webpackChunkName: "component---src-pages-policies-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-switcher-tsx": () => import("./../../../src/pages/switcher.tsx" /* webpackChunkName: "component---src-pages-switcher-tsx" */)
}

